import { Select, FormControl, MenuItem ,Button,InputLabel} from "@material-ui/core";
import React from "react";
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined';

export default function DataTableName(props) {
  const fieldStyle = "mt-4 ml-8 text-blue-900";

  return (
    <div className="bg-white">
      <div className="bg-barPrimary pl-4 py-2 text-white">
        Data Mapping
      </div>
      <div className="px-20 grid grid-cols-3 my-10">
        <div className="col-span-5 lg:col-span-1 text-buttonPrimary font-bold lg:mx-4 pt-4 lg:text-right">
          Select data table to be mapped
        </div>
        <div className="col-span-5 lg:col-span-1">
        <FormControl >
        <InputLabel>Table</InputLabel>
            <Select
   
                      className="bg-background w-60 h-full"
            value={props.hasUserSelectedTable}
            onChange={props.hasHandleUserSelectedTable}
          >
     
        
            {props.hasSqlDatatable.map((table) => (
              <MenuItem value={table.table} key={table.id}>{table.table}</MenuItem>
            ))}
          </Select>
          </FormControl>
        </div>
        <div className=" pt-4 mr-40 ">
          <Button
             variant="outlined"
             size="small"
             className="bg-buttonPrimary text-white lg:text-left "
           
            onClick={() => {
              props.isOkClicked(true);
            }}
          >
            Ok
          </Button>
        </div>
      </div>
    </div>
  );
}
