import React from "react";
import * as styles from "../../styles/dataManagment.module.css";

export default function PopUpWindow(props) {
  const modalStyle = `fixed z-10 w-full top-0 bottom-0 right-0 overflow-auto`;
  return (
    props.trigger && (
      <div
        className={`${!props.trigger && "hidden"} ${modalStyle}`}
        style={{ background: "rgb(0,0,0,0.7)" }}
      >
        <div>
          
          {props.children}
        </div>
      </div>
    )
  );
}
