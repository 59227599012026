import { Button } from "@material-ui/core";
import React from "react";
import * as XLSX from "xlsx";

export default function UploadFile(props) {
  const fieldStyle = "mt-4 ml-8 text-blue-900";

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
    var i = 0;
    promise.then((d) => {
      props.hasHeaderValueChange(Object.keys(d[i]));
    });
  };

  return (
    <div className="flex flex-col bg-white">
      {/* Data Upload */}
      <div className="bg-barPrimary pl-4 py-2 text-white">
        <strong>Data Upload</strong>
      </div>

      <div className=" px-80 grid grid-cols-3 my-8 pl-8 ">
        <div className="lg:mx-10  font-bold lg:text-right pt-1 ">Data File</div>

        <div className="relative h-full w-full ">
         
            <div className="p-1.5 rounded-md font-bold  border-buttonPrimary border-2 w-full lg:text-left ">
              <label htmlFor="files">
                Please click here to choose your data file to upload
              </label>
            </div>

            <input
              id="files"
              type="file"
              accept=".xlsx"
              style={{ visibility: "hidden" }}
              onChange={(e) => {
                const file = e.target.files[0];
                readExcel(file);
                props.parentCallback(file);
              }}
            />
          
        </div>
        <div className="lg:mx-10 pt-1">
          <Button
            variant="outlined"
            size="small"
            className="bg-buttonPrimary text-white "
            onClick={() => {
              props.isUploadDone(true);
            }}
          >
            Upload
          </Button>
        </div>
      </div>
    </div>
    // </div>
  );
}
