import {Button, Select, FormControl, MenuItem } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";

export default function ColumnName(props) {
  function handleClick() {
    props.onDelete(props.id);
  }

  return (
    <div className="py-2 px-20 bg-white">
   
      <div className="grid grid-cols-3">
        
        <div className="text-buttonPrimary font-bold m-2  ">
        <Button onClick={handleClick}>   <DeleteIcon  /></Button>
          <FormControl>
         
            <Select
              className="bg-background w-60 h-full "
              onChange={props.hasHandleUserSelectedDataColumn}
              value={props.hasUserSelectedDataBaseColumn}
            >
              <MenuItem value="">Database Column</MenuItem>
              {props.hasSqlDataTable.map(
                (table) =>
                  table.table === props.hasUserSelectedTable &&
                  props.hasOkButtonState &&
                  table.uploadDataFields.map((field) => (
                    <MenuItem
                      value={field.field}
                      key={props.hasUserSelectedTable.id}
                    >
                      {field.field}
                    </MenuItem>
                  ))
              )}
            </Select>
          </FormControl>
        </div>

        <div className="text-buttonPrimary font-bold m-2 ml-40 pl-20">
          {
            <FormControl>
              <Select
                className="bg-background w-60 h-full"
                value={props.hasUserSelectedExcelColumn}
                onChange={props.hasHandleUserSelectedExcelColumn}
              >
                 <MenuItem value="">Excel Column</MenuItem>
                 {props.hasExcelHeaderValue.map((key) => (
                  <MenuItem value={key} >{key}</MenuItem>
                ))}
              </Select>
            </FormControl>
          }
        </div>
      </div>
    </div>
  );
}
