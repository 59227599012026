import { observer } from "mobx-react"
import React from 'react';
import useStores from "../hooks/use-stores"
import Header from "./header"
import MenuBar from "./menu"



const DataLayout = observer(({ 
  children
}) => {
  const { userStore } = useStores();
  
  return (
    <div className={`grid grid-cols-6 ${userStore.userInfo.themeClass}`}>
        <div className="col-span-1">
            <MenuBar/>
        </div>
        <div className="col-span-5">
          <div className="flex flex-col">
              <Header />
              <div className="grid grid-cols-4 bg-background">
                {children}
                           
              </div>
            
          </div>
        </div>
          
    </div>
  )
})

export default DataLayout;