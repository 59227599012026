import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";

import Layout from "../../components/data-layout";
import UploadFile from "../../components/data-components/upload-component";
import PopUpWindow from "../../components/data-components/pop-up";
import DataTableName from "../../components/data-components/data-table-name";
import ColumnName from "../../components/data-components/retrive-column-names";
import useStores from "../../hooks/use-stores";

export default function Data() {
  const defaultExcelColumn = "Excel Column";
  const defaultDatabaseColumn = "Database Column";
  const { dataSheetStore } = useStores();
  const [sqlDataTable, setSqlDataTable] = useState([{}]);
  const [uploadButtonState, setUploadButtonState] = useState(false);
  const [ExcelHeaderValue, setExcelHeaderValue] = useState([]);
  const [userSelectedTable, setUserSelectedTable] = useState("");
  const [buttonPopUp, setButtonPopUp] = useState(false);
  const [notificationPopUp, setNotificationPopUp] = useState(false);
  const [mappingInfo, setMappingInfo] = useState({
    mapping: [
      {
        excelColumnName: defaultExcelColumn,
        databaseColumnName: defaultDatabaseColumn,
      },
    ],
  });
  const [fileInput, setFileInput] = useState();
  const [okButtonState, setOkButtonState] = useState(false);
  const [fileName, setFileName] = useState("");

  /*get the table name when the page loads*/
  useEffect(() => {
    dataSheetStore.getTableList().then((response) => {
      setSqlDataTable(response.data);
    })
    // GetTableList().then((response) => setSqlDataTable(response.data));
  }, []);

  /*display the selected column and clear all the data previously selected and stored data*/
  const handleUserSelectedTable = (e) => {
    setOkButtonState(false);
    mappingInfo.mapping.splice(0, mappingInfo.mapping.length);
    addToMapWithDefaultValue();
    setUserSelectedTable(e.target.value);
  };

  /* user selects column name from the selected data table  and stores it in Map object as a key-value pair*/
  const handleUserSelectedDataColumn = (event, rowIndex) => {
    setMappingInfo(() => {
      mappingInfo.mapping[rowIndex].databaseColumnName = event.target.value;
      return Object.assign({}, mappingInfo);
    });
  };
  /* user selects column name from the selected  excel table  and stores it in Map object as a key-value pair*/
  const handleUserSelectedExcelColumn = (event, rowIndex) => {
    setMappingInfo(() => {
      mappingInfo.mapping[rowIndex].excelColumnName = event.target.value;
      return Object.assign({}, mappingInfo);
    });
  };

  function handleAddField() {
    addToMapWithDefaultValue();
  }

  function handleConfirmButton(event) {
    //Remove columns with default values
    setMappingInfo(() => {
      mappingInfo.mapping = mappingInfo.mapping.filter((row) => {
        if (
          row.excelColumnName === undefined ||
          row.databaseColumnName === undefined ||
          defaultExcelColumn === row.excelColumnName ||
          defaultDatabaseColumn === row.databaseColumnName
        ) {
          return false;
        }
        return true;
      });
      return Object.assign({}, mappingInfo);
    });

    event.preventDefault();
  }

  function deleteColumn(id) {
    //Remove columns with default values
    const index = mappingInfo.mapping.indexOf(id);
    setMappingInfo(() => {
      mappingInfo.mapping.splice(index, 1);
      return Object.assign({}, mappingInfo);
    });
  }

  function addToMapWithDefaultValue() {
    setMappingInfo(() => {
      mappingInfo.mapping.push({
        excelColumnName: defaultExcelColumn,
        databaseColumnName: defaultDatabaseColumn,
      });
      return Object.assign({}, mappingInfo);
    });
  }

  function handlePostData() {
    var formdata = new FormData();
    formdata.append("", fileInput);

    // Create map out of list of objects
    let postColumnMap = new Map();
    mappingInfo.mapping.map((row) => {
      postColumnMap.set(row.excelColumnName, row.databaseColumnName);
    });

    formdata.append(
      "mappingInfo",
      JSON.stringify({
        mapping: Object.fromEntries(postColumnMap),
        tableName: userSelectedTable,
      })
    );
    console.log(
      JSON.stringify({
        mapping: Object.fromEntries(postColumnMap),
        tableName: userSelectedTable,
      })
    );
    console.log(fileInput);
    //UploadData(formdata)
    dataSheetStore.uploadData(formdata)
      .then((response) => {
        console.log(response);
        console.log(formdata);
        setNotificationPopUp(true);
      })
      .catch((error) => console.log(error));
  }

  return (
    <>
      {
        typeof window !== "undefined" &&
        <Layout>
          <div className="col-span-4 flex flex-col">
            <UploadFile
              isUploadDone={(e) => setUploadButtonState(e)}
              hasHeaderValueChange={(value) => setExcelHeaderValue(value)}
              parentCallback={(childData) => {
                setFileInput(childData);
                console.log(childData);
                setFileName(childData.name);
              }}
            />

            <DataTableName
              hasUserSelectedTable={userSelectedTable}
              hasHandleUserSelectedTable={handleUserSelectedTable}
              hasSqlDatatable={sqlDataTable}
              isOkClicked={(e) => setOkButtonState(e)}
            />
            <hr className="border-solid border-2 border-light-blue-500" />

            {/* Column Names from Database and the excel file */}
            <div className="py-2 px-10 bg-white">
              <div className="grid grid-cols-3">
                <div className="text-buttonPrimary font-bold m-2 ml-10 ">
                  <div className="mb-2 lg:ml-10">Table Column name in Database</div>
                </div>

                <div className="text-buttonPrimary font-bold m-2 ml-40 pl-20">
                  <div className="mb-2 lg:ml-3">Column Name in Data File</div>
                </div>
                <div className="col-span-5 lg:col-span-1 ml-40">
                  <Button
                    variant="outlined"
                    size="small"
                    className="bg-buttonPrimary text-white mr-4 my-4"
                    onClick={handleAddField}
                  >
                    Add Field to map
                  </Button>
                </div>
              </div>
            </div>

            <div></div>
            <form onSubmit={handleConfirmButton}>
              {mappingInfo.mapping.map((columnMapping, index) => (
                <ColumnName
                  onDelete={deleteColumn}
                  id={index}
                  key={index}
                  hasHandleUserSelectedDataColumn={(event) => {
                    handleUserSelectedDataColumn(event, index);
                  }}
                  hasUserSelectedDataBaseColumn={columnMapping.databaseColumnName}
                  hasSqlDataTable={sqlDataTable}
                  hasUserSelectedTable={userSelectedTable}
                  hasUploadButtonState={uploadButtonState}
                  hasOkButtonState={okButtonState}
                  hasUserSelectedExcelColumn={columnMapping.excelColumnName}
                  hasExcelHeaderValue={ExcelHeaderValue}
                  hasHandleUserSelectedExcelColumn={(event) => {
                    handleUserSelectedExcelColumn(event, index);
                  }}
                />
              ))}

              <div className="grid grid-cols-2 py-10 w-full bg-white">
                <div className="text-left my-10 ml-40">
                  <Button
                    className="bg-red-700 text-white "
                    component="span"
                    onClick={() => {
                      mappingInfo.mapping.splice(0, mappingInfo.mapping.length);
                      addToMapWithDefaultValue();
                      setUserSelectedTable("");
                    }}
                  >
                    Reset
                  </Button>
                </div>

                <div className="text-center my-10">
                  <Button
                    type="submit"
                    variant="outlined"
                    size="small"
                    className="bg-buttonPrimary text-white"
                    onClick={() => {
                      setButtonPopUp(true);
                    }}
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            </form>
          </div>

          <div>
            <main>
              <PopUpWindow trigger={buttonPopUp} setTrigger={setButtonPopUp}>
                <div
                  className="bg-white absolute left-1/2 top-1/2 w-3/4border-barPrimary"
                  style={{ transform: "translate(-50%, -50%)" }}
                >
                  <div className="col-span-4 flex flex-col">
                    <div className="bg-barPrimary pl-4 py-3 text-white">
                      Information confirm
                    </div>
                    <div className="px-20 grid grid-cols-2 my-2">
                      <div className="col-span-5 lg:col-span-1 text-buttonPrimary font-bold lg:mx-4 pt-2 lg:text-left">
                        Data File
                      </div>

                      <div className="col-span-5 lg:col-span-1 text-barPrimary font-bold lg:mx-4 pt-2 lg:text-left">
                        {fileName}
                      </div>
                    </div>
                    <div className="px-20 grid grid-cols-2 my-2">
                      <div className="col-span-5 lg:col-span-1 text-buttonPrimary font-bold lg:mx-4 pt-2 lg:text-left">
                        Selected data table to be mapped
                      </div>

                      <div className="col-span-5 lg:col-span-1 text-Primary font-bold lg:mx-4 pt-2 lg:text-left">
                        {userSelectedTable}
                      </div>
                    </div>

                    <hr className="border-solid border-2 border-light-blue-500 ..." />

                    {/* Excel-data column--------> Database column <br /> */}
                    <div>
                      <div className="py-0 px-10 bg-white">
                        <div className="grid grid-cols-2">
                          <div className="text-buttonPrimary font-bold m-4 ml-5 pr-40">
                            <div className="mb-1">
                              Table Column name in Database
                            </div>
                          </div>

                          <div className="text-buttonPrimary font-bold m-4 ml-5 pr-40">
                            <div className="mb-1">Column Name in Data File</div>
                          </div>
                        </div>
                      </div>
                      <div className="py-0 px-10 bg-white">
                        {mappingInfo.mapping.map((columnMapping) => (
                          <div className=" grid grid-cols-2">
                            <div className="text-buttonPrimary font-bold  ml-5 pr-40">
                              <div className="mb-2">
                                <input
                                  className="p-2 rounded-md border-buttonPrimary border-2 w-full"
                                  type="text"
                                  defaultValue={columnMapping.databaseColumnName}
                                  key={columnMapping.databaseColumnName}
                                />
                              </div>
                            </div>
                            <div className="text-buttonPrimary font-bold  ml-5 pr-40">
                              <div className="mb-2">
                                <input
                                  className="p-2 rounded-md border-buttonPrimary border-2 w-full"
                                  type="text"
                                  defaultValue={columnMapping.excelColumnName}
                                  key={columnMapping.databaseColumnName}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="grid grid-cols-2 ">
                      <div>
                        <Button
                          variant="outlined"
                          size="small"
                          className="bg-buttonPrimary text-white ml-8 my-4"
                          onClick={() => setButtonPopUp(false)}
                        >
                          Back
                        </Button>
                      </div>

                      <div className="text-right">
                        <Button
                          variant="outlined"
                          size="small"
                          className="bg-buttonPrimary text-white mr-8 my-4"
                          onClick={handlePostData}
                        >
                          SUBMIT
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </PopUpWindow>
            </main>
          </div>
          <div>
            <PopUpWindow
              trigger={notificationPopUp}
              setTrigger={setNotificationPopUp}
            >
              <div
                className="bg-white absolute left-1/2 top-1/2 h-1/2 w-1/2  border-barPrimary"
                style={{ transform: "translate(-50%, -50%)" }}
              >
                <div className="bg-barPrimary pl-4 py-6 text-white"></div>

                <div className="p-10 font-bold mt-40 ml-60  border-buttonPrimary border-2 w-1/2 lg:text-center ">
                  <div className="grid grid-cols-1 place-content-center h-22 ...">
                    Data upload sucessfully
                  </div>
                </div>
                <div className="text-right mt-20">
                  <Button
                    variant="outlined"
                    size="small"
                    className="bg-buttonPrimary text-white mr-8 my-4  "
                    onClick={() => {
                      setButtonPopUp(false);
                      setNotificationPopUp(false);
                      mappingInfo.mapping.splice(0, mappingInfo.mapping.length);
                      addToMapWithDefaultValue();
                      setUserSelectedTable("");
                    }}
                  >
                    OK
                  </Button>
                </div>
              </div>
            </PopUpWindow>
          </div>
        </Layout>
      }
    </>
  );
}
